import React, { FunctionComponent, useEffect, useState } from "react";
import styled from "styled-components";

const Button = styled.div`
  color: white;
  background-color: none;
  border: solid #fcaeea 2px;
  border-radius: 12px;
  width: max-content;
  padding: 2px 6px;
  margin-top: 4px;
  margin-bottom: 4px;
  font-size: 12px;
  margin-right: 4px;
`;

type CustomButtonProps = {
  text: string;
};

const CustomButton: FunctionComponent<CustomButtonProps> = ({ text }) => {
  return <Button>{text}</Button>;
};

export default CustomButton;
