import React, { FunctionComponent, useEffect, useState } from "react";
import styled from "styled-components";
import { keyframes } from "styled-components";
import firstRescueDemo from "../../../images/firstRescueDemo.gif";
import FirstRescueDetails from "../../../organisms/firstRescueDetails";
import ArrowBackIosIcon from "@mui/icons-material/ArrowBackIos";
import { useNavigate } from "react-router-dom";

const initialHeightAnimation = keyframes`
 0% { height: 0px;}
 100% { height: 100%;}
`;
const PageFill = styled.div`
  padding: 50px 50px;
  height: auto;
  min-height: calc(100dvh - 100px);
  left: 0;
  top: 0;
  background-color: #181818;
  animation-name: ${initialHeightAnimation};
  animation-duration: 1s;
  animation-iteration-count: 1;
  animation-timing-function: cubic-bezier(1, 0.5, 2, 2);
  @media (max-width: 1024px) {
    padding: 50px 20px;
  }
`;

const PageWrapper = styled.div`
  background-color: #181818;
  padding: 20px;
  animation-name: ${initialHeightAnimation};
  animation-duration: 1s;
  animation-iteration-count: 1;
  animation-timing-function: cubic-bezier(1, 0.5, 2, 2);
`;

const ColumnWrapper = styled.div`
  display: flex;
  min-height: calc(100dvh - 140px);
  flex-direction: row;
  justify-content: space-between;
  gap: 0.4em;
  @media (max-width: 1024px) {
    flex-direction: column;
  }
`;

const MainColumn = styled.div`
  display: flex;
  flex-direction: column;
  width: 50%;
  @media (max-width: 1024px) {
    width: 100%;
  }
`;

const DetailColumn = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  width: 50%;
  @media (max-width: 1024px) {
    width: 100%;
    margin-top: 50px;
  }
`;

const DemoGif = styled.img`
  max-height: 600px;
  max-width: 300px;
`;

const ImageContainer = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: center;
`;

const BackWrapper = styled.div`
  display: flex;
  flex-direction: row;
  cursor: pointer;
  max-width: 100px;
  color: white;
  &:hover {
    color: #fcaeea;
  }
`;

const CustomIcon = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  margin-left: 4px;
  margin-top: 6px;
`;

const BoldSubTitle = styled.h2`
  font-family: Montserrat, sans-serif;
  font-weight: 600;
  font-size: 15px;
  line-height: 1.9em;
  padding-bottom: 0px;
  margin-bottom: 2px;
`;

const FirstRescueShowcase: FunctionComponent = () => {
  let navigate = useNavigate();

  const onClickBack = () => {
    navigate("/home");
  };
  return (
    <PageFill>
      <PageWrapper>
        <ColumnWrapper>
          <MainColumn>
            <BackWrapper onClick={onClickBack}>
              <CustomIcon>
                <ArrowBackIosIcon />
              </CustomIcon>
              <BoldSubTitle>Back</BoldSubTitle>
            </BackWrapper>
            <FirstRescueDetails />
          </MainColumn>
          <DetailColumn>
            <ImageContainer>
              <DemoGif src={firstRescueDemo} alt="First Rescue Demo" />
            </ImageContainer>
          </DetailColumn>
        </ColumnWrapper>
      </PageWrapper>
    </PageFill>
  );
};

export default FirstRescueShowcase;
