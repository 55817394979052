import React, { FunctionComponent, useEffect, useState } from "react";
import styled from "styled-components";
import CustomButton from "../../atoms/customButton";
import CalendarMonthIcon from "@mui/icons-material/CalendarMonth";
import SupportIcon from "@mui/icons-material/Support";
import AccountBalanceIcon from "@mui/icons-material/AccountBalance";
import CurrencyBitcoinIcon from "@mui/icons-material/CurrencyBitcoin";
import LocalShippingIcon from "@mui/icons-material/LocalShipping";
import { Link, useNavigate } from "react-router-dom";

const Title = styled.h2`
  font-family: Montserrat, sans-serif;
  font-weight: 400;
  line-height: 1.9em;
  color: white;
  padding-bottom: 2px;
  margin-bottom: 2px;
  font-size: 28px;
`;

const SubTitle = styled.h2`
  font-family: Montserrat, sans-serif;
  font-weight: 400;
  font-size: 20px;
  line-height: 1.9em;
  color: white;
  padding-bottom: 2px;
  margin-bottom: 2px;
`;

const SubDescription = styled.h2`
  font-family: Montserrat, sans-serif;
  font-weight: 400;
  font-size: 14px;
  font-style: italic;
  line-height: 1em;
  color: white;
  padding-bottom: 2px;
  margin-bottom: 2px;
`;

const BoldSubTitle = styled.h2`
  font-family: Montserrat, sans-serif;
  font-weight: 600;
  font-size: 15px;
  line-height: 1.9em;
  color: white;
  padding-bottom: 0px;
  margin-bottom: 2px;
`;

const Description = styled.h4`
  font-family: Montserrat, sans-serif;
  font-weight: 400;
  line-height: 1.9em;
  color: #b3b3b3;
  margin: 5px 0px;
  font-size: 14px;
`;

const BoldLinks = styled.a`
  font-family: Montserrat, sans-serif;
  text-decoration: underline;
  color: #ffffff;
  font-weight: 600;
  line-height: 1.9em;

  &:hover {
    color: #fcaeea;
  }
`;

const SelfBoldLink = styled.div`
  font-family: Montserrat, sans-serif;
  text-decoration: underline;
  color: #ffffff;
  font-weight: 600;
  line-height: 1.9em;
  cursor: pointer;
  &:hover {
    color: #fcaeea;
  }
`;

const StackButtonWrapper = styled.div`
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  margin-bottom: 32px;
`;

const CustomDotpoint = styled.li`
  font-family: Montserrat, sans-serif;
  font-weight: 400;
  line-height: 1.9em;
  margin: 5px 0px;
  font-size: 14px;
  color: #b3b3b3;
`;

const TextIcon = styled.div`
  display: flex;
  flex-direction: row;
`;

const CustomIcon = styled.div`
  display: flex;
  flex-direction: column;
  color: #fcaeea;
  justify-content: center;
  margin-left: 4px;
  margin-top: 6px;
`;

const ExperienceDetails: FunctionComponent = () => {
  let navigate = useNavigate();

  const onClickFirstRescue = () => {
    navigate("../showcase/firstrescue", { replace: true });
    navigate(0);
  };

  return (
    <>
      <Title>About</Title>
      <Description>
        I am a full stack typescript developer with 5 years of experience under
        my belt. I love creating pixel perfect responsive websites and mobile
        applications on React and React Native, and working directly with
        designers with a ‘can do’ attitude to really capture an online audience.
        Apart from software development, I have had the opportunity to work
        directly with clients to build, negotiate and design end to end
        products. As such, I am an active communicator, able to negotiate
        deadlines and tweaks to designs and/or tech to increase efficiency or
        clean coding. As a PSD (Professional scrum developer) certificate
        holder, I strongly uphold agile values and introduce new ways to
        increase efficiency within the team, able to run scrum ceremonies.
      </Description>
      <Title>Experience</Title>
      <SubTitle>Zip Co - Fullstack Engineer</SubTitle>
      <SubDescription>5 months - 2024-2024</SubDescription>
      <Description>
        Completed quarterly initiatives as well as BAU support and bug fixing.
        Including systems such as{" "}
        <BoldLinks target="_blank" href="https://www.salesforce.com/au/">
          Salesforce
        </BoldLinks>{" "}
        and{" "}
        <BoldLinks target="_blank" href="https://www.dynatrace.com">
          Dynatrace
        </BoldLinks>
        .
      </Description>
      <SubDescription>Stack</SubDescription>
      <StackButtonWrapper>
        <CustomButton text="React" />
        <CustomButton text=".NET C#" />
        <CustomButton text="Microsoft SQL Server" />
        <CustomButton text="Kubernetes" />
        <CustomButton text="AWS" />
      </StackButtonWrapper>
      <SubTitle>Palo IT — Typescript Fullstack Software Engineer</SubTitle>
      <SubDescription>3 years 5 months - 2020-2023</SubDescription>
      <TextIcon>
        <BoldSubTitle>BookingSG V1.0 & RBS V2.0 </BoldSubTitle>
        <CustomIcon>
          <CalendarMonthIcon sx={{ paddingTop: "5px" }} />
        </CustomIcon>
      </TextIcon>
      <Description>
        V1.0 - created a complete booking system for admins, agency-admins and
        users to book services/agents within a calendar​
      </Description>
      <Description>
        V2.0 - created a Resource booking system for admins, agency-admins and
        users to book, including features such as Announcements, Notifications,
        Locations, User Groups etc. Final product{" "}
        <BoldLinks target="_blank" href="https://home.booking.gov.sg/">
          here
        </BoldLinks>
      </Description>
      <SubDescription>Stack</SubDescription>
      <StackButtonWrapper>
        <CustomButton text="React / Typescript frontend" />
        <CustomButton text="Nodejs / Typescript backend" />
      </StackButtonWrapper>
      <TextIcon>
        <BoldSubTitle>First Rescue - IAG</BoldSubTitle>
        <CustomIcon>
          <SupportIcon />
        </CustomIcon>
      </TextIcon>
      <SelfBoldLink onClick={onClickFirstRescue}>
        Click to view Showcase!
      </SelfBoldLink>
      <CustomDotpoint>
        Created a whitelabel web-app to link customers to towing services which
        encompasses the entire flow from raising a assistance request to
        completing a service.
      </CustomDotpoint>
      <CustomDotpoint>
        Integrated{" "}
        <BoldLinks target="_blank" href="https://developers.google.com/maps">
          Google Maps
        </BoldLinks>{" "}
        and{" "}
        <BoldLinks target="_blank" href="https://www.servicenow.com/au/">
          Service Now
        </BoldLinks>{" "}
        to create constant tracking between roadside assistance and customer
      </CustomDotpoint>
      <CustomDotpoint>
        Integrated animations within the flow to enhance the consumer
        experience​
      </CustomDotpoint>
      <SubDescription>Stack</SubDescription>
      <StackButtonWrapper>
        <CustomButton text="Nextjs" />
        <CustomButton text="React" />
        <CustomButton text="Material Ui" />
        <CustomButton text="Google Maps" />
        <CustomButton text="Service Now" />
      </StackButtonWrapper>
      <TextIcon>
        <BoldSubTitle>HSBC</BoldSubTitle>
        <CustomIcon>
          <AccountBalanceIcon />
        </CustomIcon>
      </TextIcon>
      <Description>
        Worked with HSBC team to implement a new solution for HSBC retail
        DCO/ICO website frontend to uplift the customer journey through
        application processes.
      </Description>
      <SubDescription>Stack</SubDescription>
      <StackButtonWrapper>
        <CustomButton text="React" />
        <CustomButton text="Nodejs" />
        <CustomButton text="Jenkins" />
        <CustomButton text="SonarQube​" />
      </StackButtonWrapper>
      <TextIcon>
        <BoldSubTitle>Blocktrust</BoldSubTitle>
        <CustomIcon>
          <CurrencyBitcoinIcon />
        </CustomIcon>
      </TextIcon>
      <Description>
        Completed work to completely refactor and implement a seamless and
        efficient way of coding for a white-label blockchain/nft website that
        could be applied to multiple tenants.​
      </Description>
      <SubDescription>Stack</SubDescription>
      <StackButtonWrapper>
        <CustomButton text="Nextjs Frontend" />
        <CustomButton text="C# Backend" />
      </StackButtonWrapper>
      <TextIcon>
        <BoldSubTitle>WhatTruck/Botany Access</BoldSubTitle>
        <CustomIcon>
          <LocalShippingIcon />
        </CustomIcon>
      </TextIcon>
      <Description>
        Built end to end marketplace app and web app for truck drivers to pick
        up heavy loads/machinery and posters to list their machinery to be
        delivered. You can see the final product{" "}
        <BoldLinks target="_blank" href="https://www.whattruck.com.au/">
          here
        </BoldLinks>
      </Description>
      <SubDescription>Stack</SubDescription>
      <StackButtonWrapper>
        <CustomButton text="React Native / Typescript Frontend" />
        <CustomButton text="NodeJs / Typscript Backend" />
      </StackButtonWrapper>
      <SubTitle>Zip Co - Frontend Engineer</SubTitle>
      <SubDescription>2 years - 2018-2020</SubDescription>
      <Description>
        Coding for daily marketing BAU to churn out promotional pages for new
        products and events including Black Friday, Christmas and Zip Pay.
        Worked with{" "}
        <BoldLinks target="_blank" href="https://www.contentful.com/">
          Contentful
        </BoldLinks>{" "}
        as the CMS provider of choice.
      </Description>
      <SubDescription>Stack</SubDescription>
      <StackButtonWrapper>
        <CustomButton text="Angular" />
        <CustomButton text="Contentful" />
        <CustomButton text="AWS Lambda" />
        <CustomButton text="Pug" />
      </StackButtonWrapper>
    </>
  );
};

export default ExperienceDetails;
