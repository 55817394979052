import React, { FunctionComponent, useEffect, useState } from "react";
import styled, { keyframes } from "styled-components";

const CodeDiv = styled.div<{ showDisplay?: boolean }>`
  display: ${(props) => {
    if (props.showDisplay) {
      return "flex";
    } else {
      return "none";
    }
  }};
`;

const blinkCaret = keyframes`
    from, to { border-color: transparent }
    50% { border-color: orange; }
`;

const CodeTextWrapper = styled.div`
  max-width: 500px;
  text-align: left;
`;

const CodeText = styled.h3<{ iterationCount?: number }>`
  color: white;
  margin: 0 30px;
  font-family: "JetBrains Mono";
  white-space: nowrap;
  overflow: hidden;
  border-right: 0.15em solid transparent;
  animation: ${blinkCaret} 0.8s step-end infinite;
  animation-iteration-count: ${(props) => props.iterationCount};
`;

const randomInteger = (min: number, max: number) => {
  return Math.floor(Math.random() * (max - min + 1)) + min;
};

type TypewriterProps = {
  text: string;
  iterationCount: number;
  startDelay: number;
  showDisplay: boolean;
};

const Typewriter: FunctionComponent<TypewriterProps> = ({
  text,
  iterationCount,
  startDelay,
  showDisplay,
}) => {
  const [currentText, setCurrentText] = useState("");
  const [currentIndex, setCurrentIndex] = useState(0);
  const [initialTimeout, setInitialTimeout] = useState(true);
  let delay = randomInteger(30, 100);

  useEffect(() => {
    let timeout: string | number | NodeJS.Timeout | undefined;

    if (initialTimeout) {
      setTimeout(() => {
        setInitialTimeout(false);
      }, startDelay);
    } else if (currentIndex <= text.length - 1) {
      timeout = setTimeout(() => {
        setCurrentText((prevText) => prevText + text[currentIndex]);
        setCurrentIndex((prevIndex) => prevIndex + 1);
      }, delay);
    }

    return () => clearTimeout(timeout);
  }, [currentIndex, delay, text]);
  return (
    <div>
      <CodeDiv showDisplay={showDisplay}>
        <CodeTextWrapper>
          <CodeText iterationCount={iterationCount}>
            &gt;&gt; {currentText}
          </CodeText>
        </CodeTextWrapper>
      </CodeDiv>
    </div>
  );
};

export default Typewriter;
