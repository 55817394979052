import { BrowserRouter, Route, Routes } from "react-router-dom";
import "./App.css";
import Homepage from "./pages/homepage";
import Intro from "./pages/intro";
import styled from "styled-components";
import FirstRescueShowcase from "./pages/showcase/firstRescueShowcase";
import ScrollToTop from "./helper/scrollToTop";

const PageWrapper = styled.div`
  min-height: 100vh;
  height: auto;
  background-color: #181818;
`;

function App() {
  return (
    <div className="App">
      <header className="App-header">
        <PageWrapper>
          <BrowserRouter>
            <ScrollToTop />
            <Routes>
              <Route path="/" element={<Intro />} />
              <Route path="/home" element={<Homepage />} />
              <Route
                path="/showcase/firstrescue"
                element={<FirstRescueShowcase />}
              />
            </Routes>
          </BrowserRouter>
        </PageWrapper>
      </header>
    </div>
  );
}

export default App;
