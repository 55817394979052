import React, { FunctionComponent, useEffect, useState } from "react";
import styled from "styled-components";
import "react-multi-carousel/lib/styles.css";
import Carousel from "react-multi-carousel";

const DetailWrapper = styled.div`
  position: sticky;
  top: 70px;
`;

const Name = styled.h2`
  font-family: Montserrat, sans-serif;
  font-weight: 400;
  line-height: 1.9em;
  color: white;
  padding-bottom: 2px;
  margin-bottom: 2px;
  font-size: 40px;
`;

const Role = styled.h4`
  font-family: Montserrat, sans-serif;
  font-weight: 400;
  line-height: 1.9em;
  color: white;
  margin: 5px 0px;
`;

const CarouselWrapper = styled.div`
  padding: 0px 50px 0px 0px;
  @media (max-width: 1024px) {
    padding: 0px;
  }
`;

const TestimonialsTitle = styled.h2`
  font-family: Montserrat, sans-serif;
  font-weight: 400;
  line-height: 1.2em;
  color: white;
  padding-bottom: 2px;
  margin-bottom: 2px;
  font-size: 26px;
  margin-top: 30px;
`;

const Title = styled.h2`
  font-family: Montserrat, sans-serif;
  font-weight: 400;
  line-height: 1.9em;
  color: white;
  padding-bottom: 2px;
  margin-bottom: 2px;
  font-size: 18px;
  margin-top: 0px;
`;

const Description = styled.h4`
  font-family: Montserrat, sans-serif;
  font-weight: 400;
  line-height: 1.9em;
  color: #b3b3b3;
  margin: 5px 0px;
  font-size: 14px;
`;

const SubDescription = styled.h2`
  font-family: Montserrat, sans-serif;
  font-weight: 400;
  font-size: 14px;
  font-style: italic;
  line-height: 1em;
  color: white;
  padding-bottom: 2px;
  margin-bottom: 2px;
  margin-top: 0px;
`;

const MainDetails: FunctionComponent = () => {
  const responsive = {
    superLargeDesktop: {
      // the naming can be any, depends on you.
      breakpoint: { max: 4000, min: 3000 },
      items: 1,
    },
    desktop: {
      breakpoint: { max: 3000, min: 1024 },
      items: 1,
    },
    tablet: {
      breakpoint: { max: 1024, min: 464 },
      items: 1,
    },
    mobile: {
      breakpoint: { max: 464, min: 0 },
      items: 1,
    },
  };

  return (
    <DetailWrapper>
      <Name>ELBERT WAN</Name>
      <Role>elbewa@gmail.com</Role>
      <Role>Fullstack Typescript Developer</Role>
      <Role>(Frontend Focused)</Role>
      <CarouselWrapper>
        <TestimonialsTitle>TESTIMONIALS</TestimonialsTitle>
        <Carousel responsive={responsive} swipeable={true} draggable={true}>
          <div>
            <Title>Tanguy Fournier Le Ray</Title>
            <SubDescription>Co Founder-Entrepreneur - Palo IT</SubDescription>
            <Description>
              Elbert worked in our team as a Software Engineer and has always
              been performing above expectations! I would strongly recommend him
              to any employer!
            </Description>
          </div>
          <div>
            <Title>Julian Parr</Title>
            <SubDescription>CTO at PALO IT</SubDescription>
            <Description>
              Elbert is a top quality Full Stack Software Engineer and agile
              software development practitioner, consistently delivering
              high-quality code and driving project milestones. As well as his
              technical capabilities, Elbert is a very effective communicator
              working with a wide range of people in cross-functional teams.
              Elbert would be a valuable addition to any development team
              seeking excellence in full-stack engineering.
            </Description>
          </div>
          <div>
            <Title>Ranajay Das</Title>
            <SubDescription>
              Lead Full Stack Developer at Crypto.com
            </SubDescription>
            <Description>
              I had the pleasure of working with Elbert during one of our
              projects together for First Rescue, New Zealand, and I must say
              that I was very impressed from day one! Elbert took on the role of
              lead frontend developer for the project and went above and beyond,
              both my and our client's expectations! He's great at understanding
              complex (and often hidden) requirements, has a knack for asking
              the right questions, and suggesting better ways of doing work due
              to his expertise in software development. Besides being a star in
              the tech team, he earned high praise from all levels - our design
              team, the product team, and most importantly, our client, First
              Rescue. Elbert has been a joy to work with and would make a
              fantastic addition to any team he joins!
            </Description>
          </div>
        </Carousel>
        ;
      </CarouselWrapper>
    </DetailWrapper>
  );
};

export default MainDetails;
