import React, { FunctionComponent, useEffect, useState } from "react";
import Typewriter from "../../atoms/typewriter";
import styled from "styled-components";

const CodeWrapper = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: center;
`;

const LineWrapper = styled.div`
  width: 500px;
  display: flex;
  flex-direction: column;
  padding-top: 20rem;
`;

type CodeIntroProps = {
  setCodeIntroComplete: (codeIntro: boolean) => void;
};

const CodeIntro: FunctionComponent<CodeIntroProps> = ({
  setCodeIntroComplete,
}) => {
  const [lineTwo, setLineTwo] = useState(false);
  const [lineThree, setLineThree] = useState(false);
  const [lineFour, setLineFour] = useState(false);

  useEffect(() => {
    if (!lineTwo) {
      setTimeout(() => {
        setLineTwo(true);
      }, 3000);
    } else if (!lineThree) {
      setTimeout(() => {
        setLineThree(true);
      }, 900);
    } else if (!lineFour) {
      setTimeout(() => {
        setLineFour(true);
        setCodeIntroComplete(true);
      }, 2600);
    }
  });

  return (
    <CodeWrapper>
      <LineWrapper>
        <Typewriter
          text={`console.log(Elbert Wan);`}
          iterationCount={4}
          startDelay={1000}
          showDisplay={true}
        />
        <Typewriter
          text={"Elbert Wan"}
          iterationCount={1}
          startDelay={3000}
          showDisplay={lineTwo}
        />
        <Typewriter
          text={"console.log(Tap to continue...);"}
          iterationCount={1}
          startDelay={4000}
          showDisplay={lineThree}
        />
        <Typewriter
          text={"Tap to continue..."}
          iterationCount={100}
          startDelay={6000}
          showDisplay={lineFour}
        />
      </LineWrapper>
    </CodeWrapper>
  );
};

export default CodeIntro;
