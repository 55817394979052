import React, { FunctionComponent, useEffect, useState } from "react";
import CodeIntro from "../../molecules/codeIntro";
import styled from "styled-components";
import { useNavigate } from "react-router-dom";

const PageFill = styled.div`
  min-height: 100vh;
  height: auto;
  width: 100%;
`;

const Intro: FunctionComponent = () => {
  const [codeIntroComplete, setcodeIntroComplete] = useState(false);
  const [hasClickedIntro, setHasClickedIntro] = useState(false);
  let navigate = useNavigate();

  useEffect(() => {
    document.body.addEventListener("click", (event) => {
      if (codeIntroComplete) {
        navigate("/home");
        navigate(0);
      }
    });
  }, [codeIntroComplete]);

  return (
    <PageFill>
      <CodeIntro setCodeIntroComplete={setcodeIntroComplete} />
    </PageFill>
  );
};

export default Intro;
