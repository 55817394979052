import React, { FunctionComponent, useEffect, useState } from "react";
import styled from "styled-components";
import MainDetails from "../../organisms/mainDetails";
import { keyframes } from "styled-components";
import ExperienceDetails from "../../organisms/experienceDetails";

const initialHeightAnimation = keyframes`
 0% { height: 0px;}
 100% { height: 100%;}
`;
const PageFill = styled.div`
  padding: 50px 50px;
  height: auto;
  left: 0;
  top: 0;
  background-color: #181818;
  animation-name: ${initialHeightAnimation};
  animation-duration: 1s;
  animation-iteration-count: 1;
  animation-timing-function: cubic-bezier(1, 0.5, 2, 2);
  @media (max-width: 1024px) {
    padding: 50px 20px;
  }
`;

const PageWrapper = styled.div`
  background-color: #181818;
  padding: 20px;
  animation-name: ${initialHeightAnimation};
  animation-duration: 1s;
  animation-iteration-count: 1;
  animation-timing-function: cubic-bezier(1, 0.5, 2, 2);
`;

const ColumnWrapper = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  gap: 0.4em;
  @media (max-width: 1024px) {
    flex-direction: column;
  }
`;

const MainColumn = styled.div`
  display: flex;
  flex-direction: column;
  width: 50%;
  @media (max-width: 1024px) {
    width: 100%;
  }
`;

const DetailColumn = styled.div`
  display: flex;
  flex-direction: column;
  width: 50%;
  @media (max-width: 1024px) {
    width: 100%;
  }
`;

const Homepage: FunctionComponent = () => {
  return (
    <PageFill>
      <PageWrapper>
        <ColumnWrapper>
          <MainColumn>
            <MainDetails />
          </MainColumn>
          <DetailColumn>
            <ExperienceDetails />
          </DetailColumn>
        </ColumnWrapper>
      </PageWrapper>
    </PageFill>
  );
};

export default Homepage;
