import { FunctionComponent } from "react";
import styled from "styled-components";
import CustomButton from "../../atoms/customButton";

const DetailWrapper = styled.div`
  top: 70px;
`;

const Title = styled.h2`
  font-family: Montserrat, sans-serif;
  font-weight: 400;
  line-height: 1.9em;
  color: white;
  padding-bottom: 2px;
  margin-bottom: 2px;
  font-size: 28px;
`;

const Description = styled.h4`
  font-family: Montserrat, sans-serif;
  font-weight: 400;
  line-height: 1.9em;
  color: #b3b3b3;
  margin: 5px 0px;
  font-size: 14px;
  margin-top: 16px;
`;

const BoldLinks = styled.a`
  font-family: Montserrat, sans-serif;
  text-decoration: none;
  color: white;
  font-weight: 600;
  line-height: 1.9em;
`;

const BoldWhite = styled.span`
  color: #ffffff;
  font-weight: 700;
`;

const CustomDotpoint = styled.li`
  font-family: Montserrat, sans-serif;
  font-weight: 400;
  line-height: 1.9em;
  margin: 5px 0px;
  font-size: 14px;
  color: #b3b3b3;
`;

const StackButtonWrapper = styled.div`
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  margin-bottom: 32px;
`;

const FirstRescueDetails: FunctionComponent = () => {
  return (
    <DetailWrapper>
      <Title>Project Showcase - First Rescue</Title>
      <StackButtonWrapper>
        <CustomButton text="Nextjs" />
        <CustomButton text="React" />
        <CustomButton text="Material Ui" />
        <CustomButton text="Google Maps" />
        <CustomButton text="Service Now" />
      </StackButtonWrapper>
      <Description>
        This was a three month project divided into 2 week sprints under agile
        methodologies aimed at creating a <BoldWhite>whitelabel </BoldWhite>
        mobile/web-app for requesting roadside assistance. Our team was made up
        of:
        <CustomDotpoint>Project Manager</CustomDotpoint>
        <CustomDotpoint>Three Designers</CustomDotpoint>
        <CustomDotpoint>Fullstack (mainly backend) Tech Lead</CustomDotpoint>
        <CustomDotpoint>
          Fullstack (mainly frontend) Developer (Me!)
        </CustomDotpoint>
        <CustomDotpoint>Junior Developer</CustomDotpoint>
      </Description>
      <Description>
        My role in this project included:
        <CustomDotpoint>
          90% ownership of the frontend codebase including project structure
        </CustomDotpoint>
        <CustomDotpoint>
          Integration of Service Now and Google Maps Api
        </CustomDotpoint>
        <CustomDotpoint>
          Involvement in agile tasks (Scrum, planning, retro)
        </CustomDotpoint>
        <CustomDotpoint>
          Discussions with the design team revolving customer journey and
          balancing development efficiency with design creativity
        </CustomDotpoint>
      </Description>
      <Description>
        Shown here is the flow of the app that was built. This goes through the
        entire customer journey from verification, to setting details, and then
        tracking the roadside assistance provider. It utilized Service Now for
        the tow/roadside assistance driver side, google maps to pinpoint
        location and route and polling to update the location of the driver.
      </Description>
    </DetailWrapper>
  );
};

export default FirstRescueDetails;
